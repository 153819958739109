.headerContainer {
    background-color: #c580ec;
    height: 10vh;
    width: 100%; 
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
}

.headerTitle {
    color: black;
    text-align: center;
    margin-left: 2vw;
}

.headerSubtitle {
    color: black;
    text-align: right;
    width: 100%;
    margin-right: 2vh;
}