.footerContainer {
    background-color: #c580ec;
    height: 5vh;
    width: 100%; 
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: absolute;
    top: 95vh;
    left: 0;
}

.footerText {
    color: black;
    text-align: center;
    font-size: 15px;
}
