.frontPageContainer {
    background-color: #6f85cc;
    height: 85vh;
    width: 100vw; 
    position: relative;
    left: 0;
    margin-left: 0px;
}

.frontPageText {
    color: black;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
}

.imgBox {
    height: 70vh;
    display: flex;
    justify-content: center;
}

.userImg {
    height: inherit;
    width: inherit;
    max-height: 70vh;
    max-width: 95vw;
    margin: 10px;
}

.imgInput {
    display: none;
}

.iconContainer {
    display: flex;
    justify-content: center;
}

.cameraIcon {
    width: 60px;
    cursor: pointer;
    margin: 5px 50px;
}

.descriptionContainer {
    width: 100%;
    text-align: center;
    font-size: 24px;
}

.visible {
    display: initial;
}

.invisible {
    display: none;
}